import * as React from 'react'
import * as css from './FeatureList.module.css'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FaCheck } from 'react-icons/fa'
import Cta from 'components/common/CTA'

const Checklist = ({ title, description, features, cta, image }) => (
  <section className="container-lg padding-y">
    <div className={`${css.grid} grid`}>
      <div className={css.img}>
        <figure className="styled-corner-left styled-corner-right">
          <GatsbyImage
            image={getImage(image.asset)}
            alt={title}
            objectFit="cover"
          />
        </figure>
      </div>

      <div className="container-txt padding-x">
        <header className="richtext">
          <h2 className="h0 cps-navy">{title}</h2>
          <p>{description}</p>
        </header>
        <ul>
          {features.map((feature, key) => (
            <li className={css.feature} key={key}>
              <FaCheck className={css.checkmark} />
              <div>
                <h3 className={`${css.title} h3 cps-navy`}>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            </li>
          ))}
        </ul>
        <p className={css.cta}><Cta {...cta} /></p>
      </div>
    </div>
  </section>
)

export default Checklist
