import * as React from 'react'
import * as css from './Testimonials.module.css'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { ImQuotesLeft } from 'react-icons/im'
import SanityBlock from 'components/common/SanityBlock'
import clsx from 'clsx'

const Testimonials = ({ title, items }) => {
  const [$active, set$active] = React.useState(0)

  function handleClick(key) {
    set$active(key)
  }

  return (
    <section className="container padding">
      {title && <h2>{title}</h2>}

      {items.map((testimonial, i) => (
        <div className={clsx(css.testimonial, i === $active && css.active)} key={i}>
          <figure className="text-center">
            {testimonial.image?.asset && (
              <GatsbyImage
                image={getImage(testimonial.image.asset)}
                alt={testimonial.author}
              />
            )}
          </figure>

          <div className={`${css.txt} richtext`}>
            <span className={css.quotation}>
              <ImQuotesLeft />
            </span>

            <div className={`${css.title} h0 cps-navy`}>{testimonial.title}</div>
            <blockquote className="unstyled">
              <SanityBlock body={testimonial._rawQuote} />
            </blockquote>
            <div>
              <div className={`${css.author} uppercase`}>{testimonial.author}</div>
              <div className={`${css.authorTitle} uppercase`}>{testimonial.authorTitle}</div>
            </div>

            <div className={css.clickers}>
              {items.map((clicker, j) => (
                <button
                  className={clsx(css.clicker, j === $active && css.active)}
                  onClick={() => handleClick(j)} key={j}
                >
                  {clicker.author}
                </button>
              ))}
            </div>
          </div>
        </div>
      ))}
    </section>
  )
}

export default Testimonials
