import * as React from 'react'
import * as css from './News.module.css'
import { Link } from 'gatsby'
import { CgChevronRight } from 'react-icons/cg'
import clsx from 'clsx'

const News = ({ post, large }) => (
  <article className={clsx(css.root, large && css.large)}>
    <Link
      className={clsx(css.title, large ? 'h2' : 'h4', 'link line-clamp')}
      to={`/news/${post.seo.slug.current}`}
    >
      {post.title}
    </Link>

    <time className={`${css.date} uppercase`} dateTime={post.publishDate}>
      {post.formattedPublishDate}
    </time>

    {large && <>
      <p className={`${css.desc} line-clamp`}>{post.seo.description}</p>

      <div className={css.cta}>
        <Link className="link with-icon" to={`/news/${post.seo.slug.current}`}>
          Read more
          <CgChevronRight />
        </Link>
      </div>
    </>}
  </article>
)

export default News
