import * as React from 'react'
import * as css from './TextItemList.module.css'
import SanityBlock from 'components/common/SanityBlock'
import clsx from 'clsx'

const TextItemList = ({ title, items, className }) => (
  <section className={clsx(className, 'container padding')}>
    {title && (
      <header className={css.header}>
        {title && <h2 className="uppercase">{title}</h2>}
      </header>
    )}

    <ul className={css.list}>
      {items?.map((item, key) => (
        <li className="card richtext" key={key}>
          {item.pretitle && <p className="uppercase">{item.pretitle}</p>}

          <div className="richtext">
            {item.title && <h3 className="h2">{item.title}</h3>}
            {item.subtitle && <p className="uppercase">{item.subtitle}</p>}
            {item._rawDescription && <SanityBlock body={item._rawDescription} />}
          </div>
        </li>
      ))}
    </ul>
  </section>
)

export default TextItemList
