// extracted by mini-css-extract-plugin
export var active = "Testimonials-module--active--zwcPN";
export var appear = "Testimonials-module--appear--NhOmA";
export var author = "Testimonials-module--author--PZSPT";
export var authorTitle = "Testimonials-module--authorTitle--Xszdh";
export var clicker = "Testimonials-module--clicker--l5CAm";
export var clickers = "Testimonials-module--clickers--1Zp0h";
export var quotation = "Testimonials-module--quotation--HNAme";
export var testimonial = "Testimonials-module--testimonial--Jh77C";
export var title = "Testimonials-module--title--hNU+J";
export var txt = "Testimonials-module--txt--bAKsG";