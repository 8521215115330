import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as css from './LogoList.module.css'

const LogoList = ({ title, items }) => (
  <section className="container padding">
    <h2 className={`${css.title} h2 cps-blue text-center`}>{title}</h2>

    <ul className={css.list}>
      {items.map((logo, key) => (
        <li key={key}>
          <GatsbyImage
            image={getImage(logo.image.asset)}
            alt={logo.alt}
          />
        </li>
      ))}
    </ul>
  </section>
)

export default LogoList
