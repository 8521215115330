import * as React from 'react'
import DefaultLayout from 'layouts/Default'
import { graphql, useStaticQuery } from 'gatsby'
import { GlobalContext, GlobalProvider } from 'components/common/GlobalContext'
import HeroWrapper from 'components/pg-home/HeroWrapper'
import HomeHero from 'components/pg-home/Hero'
import UrgentNews from 'components/pg-home/UrgentNews'
import QuickLinks from 'components/common/QuickLinks'
import TextItemList from 'components/common/TextItemList'
import FeatureList from 'components/pg-home/FeatureList'
import Divider from 'components/common/Divider'
import LogoList from 'components/pg-home/LogoList'
import Testimonials from 'components/common/Testimonials'
import Hero from 'components/common/Hero'

const App = ({ pg }) => {
  const { isAgent } = React.useContext(GlobalContext)

  return <>
    <HeroWrapper image={pg.hero.image} large={!isAgent}>
      {isAgent ? <UrgentNews /> : <HomeHero {...pg.hero} />}
    </HeroWrapper>
    <QuickLinks />
    <TextItemList {...pg.textItemList} />
    <FeatureList {...pg.featureList} />
    <Divider />
    <LogoList {...pg.logoList} />
    <Divider />
    <Testimonials {...pg.testimonials} />
    <Hero {...pg.bottomHero} />
  </>
}

const HomePg = () => {
  const { sanityHomePg: pg } = useStaticQuery(query)

  return (
    <DefaultLayout {...pg.seo}>
      <GlobalProvider>
        <App pg={pg} />
      </GlobalProvider>
    </DefaultLayout>
  )
}

export default HomePg

const query = graphql`query HomePg {
  sanityHomePg {
    hero { ...hero }
    textItemList {
      title
      items {
        pretitle
        title
        _rawDescription
      }
    }
    featureList {
      title
      description
      features {
        title
        description
      }
      cta { ...cta }
      image { asset { gatsbyImageData(placeholder: NONE) } }
    }
    logoList {
      title
      items {
        image { asset { gatsbyImageData(placeholder: NONE, height: 100) } }
        alt
      }
    }
    testimonials {
      title
      items {
        title
        _rawQuote
        author
        authorTitle
        image { asset { gatsbyImageData(placeholder: NONE) } }
      }
    }
    bottomHero { ...hero }
    resources {
      title
      resources {
        title
        _rawDescription
      }
    }
    seo {
      title
      description
    }
  }
}`
