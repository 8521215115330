import * as React from 'react'
import * as css from './UrgentNews.module.css'
import { graphql, useStaticQuery, Link } from 'gatsby'
import News from './News'

const UrgentNews = () => {
  const { allSanityBlogPost } = useStaticQuery(query)

  const [firstPost, ...theRest] = allSanityBlogPost.nodes
  const sortedPosts = theRest?.sort((a, b) => (
    new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime()
  ))

  return (
    <div className={css.root}>
      <div className="richtext">
        <div className="uppercase">Latest News</div>
        <News post={firstPost} large />
      </div>

      <div className={css.list}>
        {!!sortedPosts?.length
          ? theRest.map((post, key) => (
            <News post={post} key={key} />
          )) : (
            <div className={css.nomore}>No more news</div>
          )
        }

        <Link className="button full" to="/news/?category=latest-news">
          View all Urgent News
        </Link>
      </div>
    </div>
  )
}

export default UrgentNews

const query = graphql`query UrgentNews {
  allSanityBlogPost(
    filter: {category: {elemMatch: {title: {eq: "Latest News"}}}}
    sort: {fields: publishDate, order: DESC}
    limit: 4
  ) {
    nodes {
      title
      publishDate
      formattedPublishDate: publishDate(formatString: "MMM D, YYYY")
      seo {
        description
        slug { current }
      }
    }
  }
}`
