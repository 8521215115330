import * as React from 'react'
import * as css from './Hero.module.css'
import SanityBlock from 'components/common/SanityBlock'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Hero = ({ title, _rawDescription, image }) => (
  <section className={`${css.root} container-lg padding-y grid`}>
    <div className="container-txt richtext padding-x">
      {title && <h2 className="h0 cps-navy">{title}</h2>}
      {_rawDescription && <SanityBlock body={_rawDescription} />}
    </div>

    <div className={css.img}>
      {image && (
        <figure className="styled-corner-left styled-corner-right">
          <GatsbyImage
            image={getImage(image.asset)}
            alt={title}
          />
        </figure>
      )}
    </div>
  </section>
)

export default Hero
