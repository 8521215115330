import * as React from 'react'
import * as css from './Hero.module.css'
import { useAuth0 } from '@auth0/auth0-react'
import SanityBlock from 'components/common/SanityBlock'
import { Link } from 'gatsby'

const Hero = ({ title, _rawDescription }) => {
  const { loginWithRedirect } = useAuth0()

  return (
    <div className={`${css.root} styled-corner-left`}>
      <div className={`${css.inner} richtext shadow`}>
        <h1 className={`${css.title} h1 cps-blue`}>{title}</h1>
        <SanityBlock body={_rawDescription} />

        <div className={css.actions}>
          <Link to="/register" className={`${css.btn} action`}>Register Now</Link>
          <button className={`${css.btn} button`} onClick={loginWithRedirect}>Sign in</button>
        </div>
      </div>
    </div>
  )
}

export default Hero
