import * as React from 'react'
import * as css from './HeroWrapper.module.css'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import clsx from 'clsx'

const HeroWrapper = ({ image, large, children }) => (
  <section className={clsx(css.root, large && css.large, 'padding')}>
    <GatsbyImage
      className={css.img}
      image={getImage(image.asset)}
      alt="" draggable="false"
    />

    <div className={`${css.wrapper} container`}>
      {children}
    </div>
  </section>
)

export default HeroWrapper
